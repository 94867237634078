<template>
  <button :class="['Search-engine-mobile', { 'Search-engine-mobile--thin': isThin }]" @click="toggleSearchModal" ref="button" type="button">
    <span class="accessibility-sr-only">{{ $t('ux.molecules.searchEngine.searchForARestaurant') }}</span>
    <div aria-hidden="true" class="Search-engine-mobile__details">
      <ux-atoms-typo :text="displayDatePlaceholder" as="p" variant="text-small" />
      <ux-atoms-typo as="p" color="light" variant="text-small"
        >{{ displayedDate }} • {{ $t('ux.molecules.searchEngine.people', { n: people }) }}
      </ux-atoms-typo>
    </div>
    <ux-atoms-icon
      :description="$t('ux.molecules.searchEngine.search')"
      class="Search-engine-mobile__details"
      name="search-rounded"
      size="xl"
      v-if="!isThin"
    />

    <Teleport to="#teleports">
      <ux-molecules-modal
        :event-element="button"
        :has-header="false"
        button-no-border
        button-position="left"
        has-label-id
        icon-name="chevron-left"
        size="medium"
        v-model="searchModalIsOpen"
      >
        <ux-molecules-search-engine-modal-content
          :group-size="people"
          :is-submit-disabled="isSubmitDisabled"
          :start-date="date"
          @search-engine-modal-content::close-modal="toggleSearchModal"
          @search-engine-modal-content::submit-search="submitSearch"
          @search-engine-modal-content::update-date="updateDate"
          @search-engine-modal-content::update-people="updatePeople"
          @search-engine-modal-content::update-place="updatePlace"
          v-model:place-updatable="placeModel"
        />
      </ux-molecules-modal>
    </Teleport>
  </button>
</template>
<script lang="ts" setup>
import { dateClassic } from '~/helpers/date';

export interface SearchEngineMobileProps {
  date: string;
  isSubmitDisabled: boolean;
  isThin: boolean;
  people: number;
  placeUpdatable: string | undefined;
}

const props = withDefaults(defineProps<SearchEngineMobileProps>(), {
  date: undefined,
  isSubmitDisabled: false,
  isThin: false,
  people: undefined,
  placeUpdatable: undefined,
  startDate: undefined
});

const emit = defineEmits([
  'search-engine-mobile::submit-search',
  'search-engine-mobile::update-date',
  'search-engine-mobile::update-people',
  'search-engine-mobile::update-place'
]);

const { t } = useI18n();

const button = ref<HTMLElement>();
const searchModalIsOpen = ref<boolean>(false);

const dateModel = ref(props.date);
const peopleModel = ref(props.people);
const placeModel = computed({
  get: () => props.placeUpdatable,
  set: (value) => emit('search-engine-mobile::update-place', value)
});

const displayDatePlaceholder = computed(() => {
  return props.placeUpdatable ?? t('ux.molecules.searchEngine.wherePlaceholder');
});

const displayedDate = computed(() => {
  return dateClassic(dateModel.value);
});

const toggleSearchModal = () => {
  searchModalIsOpen.value = !searchModalIsOpen.value;
  nextTick(() => {
    if (!searchModalIsOpen.value && button.value) {
      button.value?.focus();
    }
  });
};

const updateDate = (dateValue: string) => {
  dateModel.value = dateValue;
  emit('search-engine-mobile::update-date', dateModel.value);
};

const updatePeople = (peopleValue: number) => {
  peopleModel.value = peopleValue;
  emit('search-engine-mobile::update-people', peopleModel.value);
};

const updatePlace = (placeValue: string) => {
  placeModel.value = placeValue;
};

const submitSearch = () => {
  emit('search-engine-mobile::submit-search');
};
</script>
<style lang="scss" scoped>
@use 'SearchEngineMobile.scss';
</style>
