import { defineStore } from 'pinia';

import { Suggestion, useGetSuggestionsLazyQuery } from '~/graphql';

export const useSearchEngineStore = defineStore('searchEngineStore', () => {
  const { t } = useI18n();
  const geolocationStore = useGeolocationStore();

  const suggestionsResult = ref<Array<Suggestion | null | undefined> | null>(null);
  const currentInput = ref<string>('');
  const currentDestination = ref<Partial<Suggestion> | null>();

  const hasSuggestionHasResults = computed(() => {
    return suggestionsResult?.value ? suggestionsResult.value.length > 0 : false;
  });

  const submitSearch = (date: string, guests: number) => {
    const localePath = useLocalePath();
    if (currentDestination.value) {
      switch (currentDestination.value.__typename) {
        case 'CitySuggestion': {
          return localePath(`/${currentDestination.value.countrySlug}/${currentDestination.value.citySlug}/map?date=${date}&guests=${guests}`);
        }
        case 'CountrySuggestion': {
          return localePath(`/${currentDestination.value.countrySlug}?date=${date}&guests=${guests}`);
        }
        case 'RestaurantSuggestion': {
          return localePath(`/restaurant/${currentDestination.value.code}?date=${date}&guests=${guests}`);
        }
        default: {
          const getCurrentDestinationDescription =
            currentDestination.value.description === t('ux.molecules.locationSelector.nearMe')
              ? geolocationStore.getLocalizationStringify()
              : currentDestination.value.description;

          return localePath(`/point-of-interest/${getCurrentDestinationDescription}/map?date=${date}&guests=${guests}`);
        }
      }
    }

    const { lat, lon } = geolocationStore;

    return localePath(`/point-of-interest/${lat},${lon}/map?date=${date}&guests=${guests}`);
  };

  const handleAutocomplete = (search: string) => {
    const { load, onResult } = useGetSuggestionsLazyQuery({
      search
    });

    onResult((response) => {
      if (response.loading) return;
      suggestionsResult.value = response.data?.getSuggestions || [];
    });

    load();
  };

  const getSuggestionsResults = () => {
    return suggestionsResult.value;
  };

  const getCurrentInput = () => {
    return currentInput.value;
  };

  const setCurrentInput = (input: string) => {
    currentInput.value = input;
  };

  const getCurrentDestination = () => {
    return currentDestination.value;
  };

  const setCurrentDestination = (destination: Partial<Suggestion> | null) => {
    currentDestination.value = destination;
  };

  const getDefaultDestination = (location?: string): Partial<Suggestion> | null => {
    const { params } = useRoute();
    const city = params.city;
    const country = params.country;
    const slug = params.slug;
    let destination: Partial<Suggestion> | null = null;

    if (city && country) {
      destination = {
        __typename: 'CitySuggestion',
        citySlug: city as string,
        countrySlug: country as string,
        description: location ?? ''
      };
    }

    if (!city && !country && slug) {
      const splitSlug = (slug as string).split(',');
      const isLocalization = splitSlug.length === 2 && splitSlug.every((value) => !isNaN(Number(value)));

      if (!isLocalization) {
        destination = {
          __typename: 'PoiSuggestion',
          description: slug as string
        };
      }

      if (isLocalization) {
        const { lat, lon } = geolocationStore;

        if (lat?.toString() === splitSlug[0] && lon?.toString() === splitSlug[1]) {
          destination = {
            __typename: 'PoiSuggestion',
            description: t('ux.molecules.locationSelector.nearMe')
          };
        }
      }
    }

    return destination;
  };

  const debouncedAutocomplete = useDebounceFn(handleAutocomplete, 500);

  return {
    currentInput,
    getCurrentDestination,
    getCurrentInput,
    getDefaultDestination,
    getSuggestionsResults,
    handleAutocomplete: debouncedAutocomplete,
    hasSuggestionHasResults,
    setCurrentDestination,
    setCurrentInput,
    submitSearch
  };
});
