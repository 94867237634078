<template>
  <form :class="['Search-engine-desktop', { 'Search-engine-desktop--thin': isThin }]" @submit.prevent>
    <ux-atoms-form-field-set
      :legend="$t('ux.molecules.searchEngine.searchForARestaurant')"
      :legend-is-s-r-only="true"
      class="Search-engine-desktop__fieldset"
    >
      <ul class="Search-engine-desktop__fields">
        <li class="Search-engine-desktop__place" ref="locationInputParent">
          <ux-atoms-form-text-input
            :aria-activedescendant="focusedId"
            :aria-controls="locationUniqueId"
            :aria-expanded="locationDropdownSelectorIsOpen"
            :display-label="!isThin"
            :id="inputLocUniqueId"
            :label="$t('ux.molecules.searchEngine.where')"
            :name="$t('ux.molecules.searchEngine.where')"
            :placeholder="$t('ux.molecules.searchEngine.wherePlaceholder')"
            @keydown.enter="inputLocationEnter($event)"
            @keydown.esc="inputLocationEsc($event)"
            @update:down-arrow="focusFirstLocElement()"
            @update:model-value="updatePlace($event, true)"
            aria-autocomplete="list"
            autocomplete="off"
            class="Search-engine-desktop__place-input"
            required
            role="combobox"
            v-model="placeModel"
          />
          <button @click="updatePlace(undefined)" class="Search-engine-desktop__place-clear" type="button" v-if="placeModel?.length">
            <ux-atoms-icon name="clear" size="m" />
          </button>
          <ux-molecules-modal-dropdown
            :event-element="modalDropdownLocationTarget"
            :model-value="locationDropdownSelectorIsOpen"
            @modal-dropdown:model-value="toggleLocationDropdown"
            no-focus-on-open
          >
            <ux-molecules-location-selector
              :research="placeModel"
              :unique-id="locationUniqueId"
              @location-selector::close="closeLocationDropdown"
              @location-selector::focus="updateFocusedEl"
              @location-selector::select="updatePlace($event, false)"
              ref="locationSelector"
            />
          </ux-molecules-modal-dropdown>
        </li>
        <li class="Search-engine-desktop__date">
          <button :aria-expanded="dateModalDropdownIsOpen" @click="toggleDatePicker" class="Search-engine-desktop__date-button" type="button">
            <ux-atoms-typo
              :class="['Search-engine-desktop__date-label', { 'accessibility-sr-only': isThin }]"
              :text="$t('ux.molecules.searchEngine.when')"
              as="span"
              text-align="start"
            />
            <span class="accessibility-sr-only">{{ $t('ux.molecules.searchEngine.srWhen') }}</span>
            <ux-atoms-typo :text="displayedDate" as="span" class="Search-engine-desktop__date-selected" text-align="start" />
            <ux-molecules-modal-dropdown
              :event-element="modalDropdownDateTarget"
              :model-value="dateModalDropdownIsOpen"
              @modal-dropdown:model-value="toggleDatePicker"
              ref="dateDropdown"
            >
              <ux-molecules-date-picker :model-value="dateModel" @date-picker::update-date="updateDate" />
            </ux-molecules-modal-dropdown>
          </button>
        </li>
        <li class="Search-engine-desktop__people">
          <ux-atoms-form-select
            :custom-sr-label="$t('ux.molecules.searchEngine.srWho')"
            :label="$t('ux.molecules.searchEngine.who')"
            :label-is-sr="isThin"
            :max="BOOKING_FUNNEL_MAX"
            :min="BOOKING_FUNNEL_MIN"
            :model-value="peopleModel"
            :options="getSelectOptions()"
            @update:model-value="updatePeople"
            variant="search"
          />
        </li>
      </ul>
    </ux-atoms-form-field-set>
    <button :disabled="isSubmitDisabled" @click="submitSearch(true)" type="submit">
      <ux-atoms-icon
        :class="{ 'Search-engine__details--disabled': isSubmitDisabled }"
        :description="$t('ux.molecules.searchEngine.search')"
        class="Search-engine__details"
        name="search-rounded"
        size="xl"
      />
    </button>
  </form>
</template>
<script lang="ts" setup>
import { getFirstFocusableElement } from '~/helpers/accessibility';
import { BOOKING_FUNNEL_MAX, BOOKING_FUNNEL_MIN } from '~/helpers/constants';
import { dateClassic } from '~/helpers/date';
import { gtmEvent, gtmPageName } from '~/helpers/gtm';
import { getSelectOptions } from '~/helpers/selectOptions';

export interface SearchEngineDesktopProps {
  date: string;
  isSubmitDisabled: boolean;
  isThin: boolean;
  people: number;
  placeUpdatable: string | undefined;
}

const props = withDefaults(defineProps<SearchEngineDesktopProps>(), {
  date: undefined,
  isSubmitDisabled: false,
  isThin: false,
  people: undefined,
  placeUpdatable: undefined
});

const emit = defineEmits([
  'search-engine-desktop::submit-search',
  'search-engine-desktop::update-date',
  'search-engine-desktop::update-people',
  'search-engine-desktop::update-place'
]);

const dateModalDropdownIsOpen = ref<boolean>(false);
const modalDropdownDateTarget = ref<HTMLElement | null>(null);
const modalDropdownLocationTarget = ref<HTMLElement | null | undefined>(null);

const locationUniqueId = useId();
const inputLocUniqueId = useId();
const defaultDestination = ref(false);
const locationSelector = ref();
const route = useRoute();
const locationInputParent = ref();

const dateModel = ref(props.date);
const peopleModel = ref(props.people);
const placeModel = computed({
  get: () => props.placeUpdatable,
  set: (value) => emit('search-engine-desktop::update-place', value)
});

const locationDropdownSelector = ref(false);
const dateDropdown = ref();
const previousEventClose = ref(false);

const focusedId = ref<null | string>(null);

const inputLocationEnter = (e: Event) => {
  e.preventDefault();
  openLocationDropdown();
};

const inputLocationEsc = (e: Event) => {
  e.preventDefault();
  closeLocationDropdown();
};

const updateFocusedEl = (el: Event) => {
  const target = el.target as HTMLElement;
  if (target?.id) {
    focusedId.value = target.id;
  }
};

const focusFirstLocElement = () => {
  locationSelector.value.focusFirstListItem();
};

const displayedDate = computed(() => {
  return dateClassic(dateModel.value);
});

const locationDropdownSelectorIsOpen = computed(() => {
  return Boolean(placeModel.value?.length && locationDropdownSelector.value && !previousEventClose.value && placeModel.value);
});

const toggleDatePicker = (event: Event) => {
  modalDropdownDateTarget.value = event.target as HTMLElement;
  dateModalDropdownIsOpen.value = !dateModalDropdownIsOpen.value;
};

const openLocationDropdown = () => {
  modalDropdownLocationTarget.value = getFirstFocusableElement(locationInputParent.value) as HTMLElement;
  locationDropdownSelector.value = true;
};

const closeLocationDropdown = () => {
  locationDropdownSelector.value = false;
};

const toggleLocationDropdown = (open: boolean) => {
  if (open) {
    openLocationDropdown();
  } else {
    closeLocationDropdown();
  }
};

const updateDate = (dateValue: string) => {
  dateModel.value = dateValue;
  emit('search-engine-desktop::update-date', dateModel.value);
  dateDropdown.value.close();
};

const updatePeople = (peopleValue: number) => {
  peopleModel.value = peopleValue;
  emit('search-engine-desktop::update-people', peopleModel.value);
};

const updatePlace = (result: string | undefined, openDropdown?: boolean) => {
  if (openDropdown && defaultDestination.value) {
    if (previousEventClose.value) {
      previousEventClose.value = false;
    } else {
      openLocationDropdown();
    }
  } else {
    closeLocationDropdown();
    previousEventClose.value = true;
  }

  placeModel.value = result;

  defaultDestination.value = true;
};

const submitSearch = (isFromButton?: Boolean) => {
  if (isFromButton) {
    gtmEvent('booking_form_submit', { pagename: gtmPageName(route.name as string) });
  }
  emit('search-engine-desktop::submit-search');
};
</script>
<style lang="scss" scoped>
@use 'SearchEngineDesktop.scss';
</style>
