<template>
  <div class="position-full" v-if="!isTablet">
    <ux-molecules-search-engine-search-engine-mobile
      :date="date"
      :is-submit-disabled="disableSubmit"
      :is-thin="isThin"
      :people="people"
      @search-engine-mobile::submit-search="submitSearch"
      @search-engine-mobile::update-date="updateDate"
      @search-engine-mobile::update-people="updatePeople"
      @search-engine-mobile::update-place="updatePlace"
      v-model:place-updatable="place"
    />
  </div>
  <div class="position-full" v-else>
    <ux-molecules-search-engine-search-engine-desktop
      :date="date"
      :is-submit-disabled="disableSubmit"
      :is-thin="isThin"
      :people="people"
      @search-engine-desktop::submit-search="submitSearch"
      @search-engine-desktop::update-date="updateDate"
      @search-engine-desktop::update-people="updatePeople"
      @search-engine-desktop::update-place="updatePlace"
      v-model:place-updatable="place"
    />
  </div>
</template>
<script lang="ts" setup>
import { newUTCToday } from '~/helpers/date';
import { gtmEvent, gtmPageName } from '~/helpers/gtm';

export interface SearchEngineProps {
  isThin?: boolean;
  location?: null | string;
}

const props = withDefaults(defineProps<SearchEngineProps>(), {
  isThin: false,
  location: undefined
});

const { t } = useI18n();
const store = useSearchEngineStore();
const { reset } = searchRestaurantStore();
const { isTablet } = useCurrentWindowSize();
const { searchParams } = useSearchRestaurant();
const route = useRoute();

const date = ref<string>(searchParams.value.date);
const people = ref<number>(searchParams.value.groupSize);
const place = ref<string | undefined>(store.getCurrentDestination()?.description as string);

const updateDate = (updatedDate: string) => {
  date.value = updatedDate;
  gtmEvent('search_bar_interact', {
    pagename: gtmPageName(route.name as string),
    search_action: 'modify',
    search_label: 'date'
  });
};

const updatePlace = (updatedPlace: string) => {
  place.value = updatedPlace;
  if (updatedPlace) {
    store.handleAutocomplete(updatedPlace);
  } else {
    store.setCurrentDestination(null);
  }
};

const updatePeople = (updatedPeople: number) => {
  people.value = updatedPeople;

  gtmEvent('search_bar_interact', {
    pagename: gtmPageName(route.name as string),
    search_action: 'modify',
    search_label: 'guests'
  });
};

const disableSubmit = computed(() => {
  const { lat, lon } = useGeolocationStore();
  return !place.value && (!lat || !lon);
});

const submitSearch = async () => {
  reset();

  if (date.value === undefined) {
    date.value = newUTCToday();
  }
  const url = store.submitSearch(date.value as string, people.value);

  gtmEvent('bloc_interact', {
    bloc_interaction: `search - ${place.value}`,
    bloc_type: 'search module',
    pagename: gtmPageName(route.name as string)
  });

  if (url) {
    await navigateTo(url);
  }
};

onMounted(() => {
  if (props.location) {
    const location = store.getDefaultDestination(props.location);
    if (location) {
      updatePlace(location.description === t('ux.molecules.locationSelector.nearMe') ? location.description : props.location);
      store.setCurrentDestination(location);
    }
  }
});
</script>
