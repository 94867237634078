<template>
  <div @keydown.down="focusFirstDropdownEl" class="Search-input">
    <ux-atoms-icon class="Search-input__search-icon" name="search" size="m" />
    <input
      :aria-activedescendant="ariaActivedescendant"
      :aria-autocomplete="ariaAutocomplete"
      :aria-controls="ariaControls"
      :aria-expanded="ariaExpanded"
      :autocomplete="autocomplete"
      :id="id"
      :placeholder="placeholder"
      :role="role"
      class="Search-input__input"
      required
      type="text"
      v-if="!noKeyboardPrevent"
      v-model="value"
    />
    <input
      :aria-activedescendant="ariaActivedescendant"
      :aria-autocomplete="ariaAutocomplete"
      :aria-controls="ariaControls"
      :aria-expanded="ariaExpanded"
      :autocomplete="autocomplete"
      :id="id"
      :placeholder="placeholder"
      :role="role"
      :value="value"
      @input="onInput"
      class="Search-input__input"
      required
      type="text"
      v-else
    />
    <button @click="clearSearch" class="Search-input__clear" type="button" v-if="value">
      <ux-atoms-icon name="clear" size="m" />
    </button>
  </div>
</template>
<script lang="ts" setup>
type AriaAutocomplete = 'both' | 'inline' | 'list' | 'none';

export interface TextInputProps {
  ariaActivedescendant?: string;
  ariaAutocomplete?: AriaAutocomplete;
  ariaControls?: string;
  ariaExpanded?: boolean;
  autocomplete?: string;
  id?: string;
  label?: string;
  modelValue: null | string | undefined;
  name?: string;
  noKeyboardPrevent?: boolean;
  placeholder?: string;
  role?: string;
}

const props = withDefaults(defineProps<TextInputProps>(), {
  ariaActivedescendant: undefined,
  ariaAutocomplete: undefined,
  ariaControls: undefined,
  ariaExpanded: undefined,
  autocomplete: undefined,
  id: undefined,
  label: undefined,
  modelValue: undefined,
  name: undefined,
  noKeyboardPrevent: false,
  placeholder: undefined,
  role: undefined
});

const emit = defineEmits(['update:modelValue', 'update:clearModelValue', 'update:downArrow']);

const value = useVModel(props, 'modelValue');

const onInput = (e: Event) => {
  value.value = (e.target as HTMLInputElement).value;
};

const clearSearch = () => {
  emit('update:clearModelValue', '');
};

const focusFirstDropdownEl = (e: Event) => {
  e.preventDefault();
  emit('update:downArrow');
};

watch(value, (newValue) => {
  emit('update:modelValue', newValue);
});
</script>
<style lang="scss" scoped>
@use 'SearchInput.scss';
</style>
