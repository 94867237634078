<template>
  <div
    :aria-label="`${$t('ux.molecules.locationSelector.resultsSuggestion')}`"
    :id="uniqueId"
    @keydown.down="focusNextListItem($event)"
    @keydown.up="focusPreviousListItem($event)"
    class="Location-selector"
    ref="locationList"
    role="listbox"
  >
    <div class="Location-selector__result Location-selector__result--location" role="option" v-if="geolocationStore?.lat && geolocationStore?.lon">
      <button @click="setDestination()" class="Location-selector__result__link" type="button">
        <div class="Location-selector__result-location">
          <ux-atoms-icon name="location" size="s" />
        </div>

        <ux-atoms-typo :text="$t('ux.molecules.locationSelector.nearMe')" as="p" class="Location-selector__result-title" variant="text-heading-02" />
      </button>
    </div>
    <ux-atoms-typo
      :text="$t('ux.molecules.locationSelector.suggestions')"
      as="p"
      class="Location-selector__suggestions-title"
      v-if="Array.isArray(results)"
      variant="text-heading-03"
    />
    <ul v-if="Array.isArray(results)">
      <li :key="result?.description" class="Location-selector__result" role="option" v-for="(result, index) in results">
        <button
          :id="`Location-selector__result__link--${index}`"
          @click="setDestination(result)"
          class="Location-selector__result__link"
          type="button"
        >
          <ux-atoms-image :src="result.src" alt="" class="Location-selector__result-image" height="44" icon="pin-map-background" width="44" />
          <div class="Location-selector__result-content">
            <ux-atoms-typo :text="result.description" as="p" class="Location-selector__result-title" variant="text-heading-02" />
            <div class="Location-selector__result-cooking-types" v-if="isRestaurantSuggestion(result)">
              <ux-atoms-typo :text="result.cookingType" as="span" class="Location-selector__result-cooking-type" variant="text-small" />
            </div>
          </div>
        </button>
      </li>
      <li class="Location-selector__result" key="no-result" role="presentation" v-if="!results.length">
        <div class="Location-selector__result-content">
          <ux-atoms-typo
            :text="$t('ux.molecules.searchEngine.noResult', { research: research })"
            as="p"
            class="Location-selector__result-title"
            variant="text-heading-02"
          />
        </div>
      </li>
    </ul>
  </div>
</template>
<script lang="ts" setup>
import { RestaurantSuggestion, Suggestion } from '~/graphql';
import { getFirstFocusableElement, getFocusableElements, getNextFocusableElement, getPreviousFocusableElement } from '~/helpers/accessibility';
import { capitalizeFirstLetter } from '~/helpers/capitaliseFirstLetter';

interface ExtendedRestaurantProperties extends RestaurantSuggestion {
  code?: string;
  cookingType: string;
  description: string;
  src: null | string;
}

export interface LocationSelectorProps {
  research?: string | undefined;
  uniqueId?: string;
}

withDefaults(defineProps<LocationSelectorProps>(), {
  research: undefined,
  uniqueId: undefined
});

const emit = defineEmits(['location-selector::close', 'location-selector::select', 'location-selector::focus']);

const geolocationStore = useGeolocationStore();
const searchEngineStore = useSearchEngineStore();
const { t } = useI18n();
const activeElement = useActiveElement();

const locationList = ref<HTMLElement | null>(null);
const focusableListElements = ref<HTMLElement[]>();

const results = computed(() =>
  searchEngineStore.getSuggestionsResults()?.map((result) => {
    if (result?.__typename === 'RestaurantSuggestion') {
      return {
        ...result,
        code: result?.code,
        cookingType: [result?.foodType, result?.facet, capitalizeFirstLetter(result?.city as string)].filter(Boolean).join(', '),
        description: result?.description as string,
        src: result.media?.formats?.[result.media.formats.length - 1]?.path || undefined
      };
    }
    return {
      ...result,
      description: result?.description as string,
      src: undefined
    };
  })
);

const setDestination = (result?: Partial<Suggestion>) => {
  if (result) {
    searchEngineStore.setCurrentInput(result.description as string);
    searchEngineStore.setCurrentDestination(result);
  }
  emit('location-selector::select', (result?.description as string) ?? t('ux.molecules.locationSelector.nearMe'));
  emit('location-selector::close');
};

const isRestaurantSuggestion = (result: Partial<Suggestion>): result is ExtendedRestaurantProperties => {
  return result?.__typename === 'RestaurantSuggestion';
};

const getListFocusableElements = () => {
  locationList.value?.scroll({ left: 0, top: 0 });
  focusableListElements.value = getFocusableElements(locationList.value as HTMLElement) as HTMLElement[];
};

const focusPreviousListItem = (e: Event) => {
  e.preventDefault();
  const previousEl = getPreviousFocusableElement(e.target as HTMLElement, focusableListElements.value as HTMLElement[]);
  previousEl.focus();
};

const focusNextListItem = (e: Event) => {
  e.preventDefault();
  const nextEl = getNextFocusableElement(e.target as HTMLElement, focusableListElements.value as HTMLElement[]);
  nextEl?.focus();
};

const focusFirstListItem = () => {
  if (focusableListElements.value) {
    const firstEl = getFirstFocusableElement(locationList.value as HTMLElement) as HTMLElement;
    firstEl?.focus();
  }
};

defineExpose({
  focusFirstListItem
});

watch(results, () => {
  nextTick(() => {
    getListFocusableElements();
  });
});

watch(activeElement, (focusedEl) => {
  emit('location-selector::focus', focusedEl);
});
</script>
<style lang="scss" scoped>
@use 'LocationSelector.scss';
</style>
