<template>
  <form @submit.prevent class="Search-engine-modal-content">
    <div class="Search-engine-modal-content__place-container">
      <ux-atoms-button :aria-label="$t('ux.molecules.modal.close.label')" @button::click="closeModal" variant="link">
        <ux-atoms-icon name="chevron-left" />
      </ux-atoms-button>
      <ux-atoms-form-search-input
        :aria-activedescendant="focusedId"
        :aria-controls="locationUniqueId"
        :aria-expanded="locationDropdownSelectorIsOpen"
        :id="inputLocUniqueId"
        :label="$t('ux.molecules.searchEngine.where')"
        :name="$t('ux.molecules.searchEngine.where')"
        :placeholder="$t('ux.molecules.searchEngine.wherePlaceholder')"
        @keydown.enter="inputLocationEnter($event)"
        @keydown.esc="inputLocationEsc($event)"
        @update:clear-model-value="clearPlaceModelValue"
        @update:down-arrow="focusFirstLocElement()"
        @update:model-value="updatePlace($event, true)"
        aria-autocomplete="list"
        autocomplete="off"
        no-keyboard-prevent
        required
        role="combobox"
        v-model="placeModel"
      />
      <ux-molecules-modal-dropdown :model-value="locationDropdownSelectorIsOpen" @modal-dropdown:model-value="closeLocationDropdown" no-focus-on-open>
        <ux-molecules-location-selector
          :research="placeModel"
          :unique-id="locationUniqueId"
          @location-selector::close="closeLocationDropdown"
          @location-selector::focus="updateFocusedEl"
          @location-selector::select="updatePlace($event, false)"
          ref="locationSelector"
        />
      </ux-molecules-modal-dropdown>
    </div>
    <ux-atoms-form-field-set
      :legend="$t('ux.molecules.searchEngineModalContent.date')"
      class="Search-engine-modal-content__form-container"
      variant="booking"
    >
      <span class="accessibility-sr-only">{{ $t('ux.molecules.searchEngine.srWhen') }}</span>
      <ux-atoms-button
        :label="$t('ux.molecules.searchEngineModalContent.moreDates')"
        @click="toggleModal"
        class="Search-engine-modal-content__more-dates"
        variant="link"
      />

      <ux-molecules-date-selector :selected-date="dateModel" :start-date="startDate" @date::select="updateDate" is-date-picker />
    </ux-atoms-form-field-set>

    <div class="Search-engine-modal-content__form-container">
      <ux-atoms-form-select
        :label="$t('ux.molecules.searchEngineModalContent.people')"
        :max="BOOKING_FUNNEL_MAX"
        :min="BOOKING_FUNNEL_MIN"
        :model-value="peopleModel"
        :options="getSelectOptions()"
        @update:model-value="updatePeople"
      />
    </div>

    <div class="Search-engine-modal-content__submit-container">
      <ux-atoms-button
        :disabled="isSubmitDisabled"
        :label="$t('ux.molecules.searchEngineModalContent.search')"
        @click="handleSubmit(true)"
        type="submit"
        variant="tertiary"
      />
    </div>
    <ux-molecules-modal
      :event-element="modalTarget"
      :id="id"
      :model-value="modalIsOpen"
      @update:model-value="toggleModal($event)"
      button-no-border
      button-position="right"
      has-label-id
      is-sub-modal
      position="bottom"
      size="medium"
    >
      <ux-molecules-date-picker :model-value="dateModel" @date-picker::update-date="updateDate" class="Search-engine-modal-content__date-picker" />
    </ux-molecules-modal>
  </form>
</template>
<script lang="ts" setup>
import { BOOKING_FUNNEL_DEFAULT, BOOKING_FUNNEL_MAX, BOOKING_FUNNEL_MIN } from '~/helpers/constants';
import { gtmEvent, gtmPageName } from '~/helpers/gtm';
import { getSelectOptions } from '~/helpers/selectOptions';

export interface SearchEngineModalContentProps {
  groupSize: number;
  isSubmitDisabled: boolean;
  placeUpdatable?: string | undefined;
  startDate: string;
}

const props = withDefaults(defineProps<SearchEngineModalContentProps>(), {
  groupSize: BOOKING_FUNNEL_DEFAULT,
  isSubmitDisabled: false,
  placeUpdatable: undefined,
  startDate: undefined
});

const emit = defineEmits([
  'search-engine-modal-content::close-modal',
  'search-engine-modal-content::update-date',
  'search-engine-modal-content::update-people',
  'search-engine-modal-content::update-place',
  'search-engine-modal-content::submit-search'
]);

const id = useId();
const { t } = useI18n();

const route = useRoute();
const locationUniqueId = useId();
const inputLocUniqueId = useId();
const modalTarget = ref<HTMLElement | null>(null);
const locationSelector = ref();
const modalIsOpen = ref<boolean>(false);
const dateModel = ref(props.startDate);
const peopleModel = ref<number>(props.groupSize);
const placeModel = computed({
  get: () => props.placeUpdatable,
  set: (value) => emit('search-engine-modal-content::update-place', value)
});
const locationDropdownSelector = ref(false);
const previousEventClose = ref(false);
const focusedId = ref<null | string>(null);

const locationDropdownSelectorIsOpen = computed(() => {
  return Boolean(locationDropdownSelector.value && placeModel.value?.length);
});

const inputLocationEnter = (e: Event) => {
  e.preventDefault();
  openLocationDropdown();
};

const inputLocationEsc = (e: Event) => {
  e.preventDefault();
  closeLocationDropdown();
};

const updateFocusedEl = (el: Event) => {
  const target = el.target as HTMLElement;
  if (target?.id) {
    focusedId.value = target.id;
  }
};

const focusFirstLocElement = () => {
  locationSelector.value.focusFirstListItem();
};

const clearPlaceModelValue = () => {
  placeModel.value = undefined;
};

const updateDate = (updatedDate: string) => {
  dateModel.value = updatedDate;
  modalIsOpen.value = false;
  emit('search-engine-modal-content::update-date', dateModel.value as string);
};

const updatePeople = (people: number) => {
  peopleModel.value = people;
  emit('search-engine-modal-content::update-people', people as number);
};

const openLocationDropdown = () => {
  locationDropdownSelector.value = true;
};

const closeLocationDropdown = () => {
  locationDropdownSelector.value = false;
};

const updatePlace = (result: string, openDropdown: boolean) => {
  if (openDropdown) {
    if (previousEventClose.value) {
      previousEventClose.value = false;
    } else {
      openLocationDropdown();
    }
  } else {
    if (result) {
      placeModel.value = result;
    } else {
      placeModel.value = t('ux.molecules.locationSelector.nearMe');
    }
    closeLocationDropdown();
    previousEventClose.value = true;
  }
};

const toggleModal = (targetElement: HTMLElement) => {
  modalTarget.value = targetElement;
  modalIsOpen.value = !modalIsOpen.value;
};

const closeModal = () => {
  emit('search-engine-modal-content::close-modal');
  nextTick(() => {
    window.scrollTo({ top: 0 });
  });
};

const handleSubmit = (isFromButton?: Boolean) => {
  if (isFromButton) {
    gtmEvent('booking_form_submit', { pagename: gtmPageName(route.name as string) });
  }

  emit('search-engine-modal-content::submit-search');
  // forcing closing modal in case just the query params are updated
  closeModal();
};
</script>
<style lang="scss" scoped>
@use 'SearchEngineModalContent.scss';
</style>
